<template>
  <div>
    <v-card>
      <v-card-title>
        Encerramento de grupo <v-spacer></v-spacer> Items: {{ dataLength }}
      </v-card-title>

      <v-divider class="mt-0"></v-divider>

      <!--table-->
      <v-data-table
        :headers="headers"
        :items="closingGroupList"
        :loading="loading"
        loading-text="Carregando dados..."
        fixed-header
        :height="heightDinamic"
        disable-sort
      >
        <!--data empty-->
        <template v-slot:no-data>
          {{ noDataText }}
        </template>

        <!--field phone_cell-->
        <template #[`item.phone`]="{item}">
          <v-icon
            medium
            @click="chatWhatsapp(item.phone)"
          >
            {{ icons.mdiWhatsapp }}
          </v-icon>
          {{ item.phone }}
        </template>
      </v-data-table>

      <v-divider class="mt-4"></v-divider>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@axios'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterRemove,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiWhatsapp,
} from '@mdi/js'

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    icons: {
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiFileDocumentOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiExportVariant,
      mdiAccountOutline,
      mdiFilter,
      mdiFilterRemove,
      mdiWhatsapp,
    },

    /* data table */
    closingGroupList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDinamic: '600px',
    loading: true,
    dataLength: '',
    headers: [
      { text: 'ENCERRAMENTO', value: 'closing' },
      { text: 'CLIENTE', value: 'client_name' },
      { text: 'PROPOSTA', value: 'propose' },
      { text: 'GRUPO', value: 'group_quota_r_d' },
      { text: 'ULT PARCELA', value: 'parcel_last_date' },
      { text: 'STATUS', value: 'status' },
      { text: 'TELEFONE 1', value: 'phone_cell' },
      { text: 'TELEFONE 2', value: 'phone' },
      { text: 'MODELO', value: 'vehicle_model' },
    ],
  }),

  watch: {
    //
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      await axiosIns
        .get('/api/v1/integration/contract/group_closing')
        .then(res => {
          res.data.data.map(value => {
            this.closingGroupList.push({
              closing: value.closing,
              client_name: value.client_name,
              propose: `${value.proposal_number ?? ''}-${value.proposal_digit ?? ''}`,
              group_quota_r_d: `${value.group_number ?? ''} ${value.quota_number ?? ''} ${value.quota_digit ?? ''}`,
              parcel_last_date: this.formatDate(value.parcel_last_date),
              contract_status: value.contract_status,
              phone_cell: value.phone_cell,
              phone: value.phone,
              vehicle_model: value.vehicle_model,
            })
          })
          this.dataLength = res.data.data.length
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    chatWhatsapp(phone) {
      window.open(`https://wa.me/55${phone}`, '_blank')
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },
}
</script>
