import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,[_vm._v(" Encerramento de grupo "),_c(VSpacer),_vm._v(" Items: "+_vm._s(_vm.dataLength)+" ")],1),_c(VDivider,{staticClass:"mt-0"}),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.closingGroupList,"loading":_vm.loading,"loading-text":"Carregando dados...","fixed-header":"","height":_vm.heightDinamic,"disable-sort":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.noDataText)+" ")]},proxy:true},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"medium":""},on:{"click":function($event){return _vm.chatWhatsapp(item.phone)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiWhatsapp)+" ")]),_vm._v(" "+_vm._s(item.phone)+" ")]}}],null,true)}),_c(VDivider,{staticClass:"mt-4"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }